export const RESOURCES = [
  { name: "atto", memory: "64MB", class: "r", cpu: "30m", price: 0.75 },
  { name: "atto", memory: "64MB", class: "m", cpu: "20m", price: 0.60 },
  { name: "atto", memory: "64MB", class: "c", cpu: "60m", price: 0.90 },
  { name: "femto", memory: "128MB", class: "r", cpu: "60m", price: 1.50 },
  { name: "femto", memory: "128MB", class: "m", cpu: "30m", price: 1.20 },
  { name: "femto", memory: "128MB", class: "c", cpu: "90m", price: 1.75 },
  { name: "pico", memory: "256MB", class: "r", cpu: "120m", price: 3.00 },
  { name: "pico", memory: "256MB", class: "m", cpu: "90m", price: 2.40 },
  { name: "pico", memory: "256MB", class: "c", cpu: "150m", price: 3.50 }
];

export const pageHeaders = [
  { title: 'Dashboard', url: '/dashboard' , subText:'Page description goes here - eg. manage and track your deployments'},
  { title: 'Deployments', url: '/deployment', subText:'Manage and Track Your Deployments Efficiently' },
  { title: 'New Project', url: '/create-deployment', subText:'Deploy your app to production effortlessly' },
  { title: 'Tools', url: '/tools', subText:'' },
  { title: 'Endpoints', url: '/endpoints', subText:'' },
  { title: 'Configs', url: '/configs', subText:'' },
  { title: 'Secrets', url: '/secrets', subText:'' },
  { title: 'Volumes', url: '/volumes', subText:'' },
  { title: 'Settings', url: '/settings', subText:'' },
  { title: 'Deployment Details', url: '/deployment-details', subText:'' },
];